import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';


import PrimeVue from 'primevue/config';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Ripple from 'primevue/ripple';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Steps from "primevue/steps";

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const app = createApp(App);

const firebaseConfig = {
    apiKey: "AIzaSyCGBWhXY5PRdKkFdO55DR26crirHG-b3G4",
    authDomain: "clinical-mind-site.firebaseapp.com",
    projectId: "clinical-mind-site",
    storageBucket: "clinical-mind-site.appspot.com",
    messagingSenderId: "176496500548",
    appId: "1:176496500548:web:a465e600cc936d63064111",
    measurementId: "G-MVBLVHEP7W"
};

initializeApp(firebaseConfig);
getAnalytics();

app.use(PrimeVue,
    {
        ripple: true,
        inputStyle: 'outlined',
        locale: {
            startsWith: 'Começa com',
            contains: 'Contém',
            notContains: 'Não contém',
            endsWith: 'Termina com',
            equals: 'É Igual',
            notEquals: 'Não é igual',
            noFilter: 'Sem Filtro',
            lt: 'Menor que',
            lte: 'Menos que ou igual a',
            gt: 'Maior que',
            gte: 'Melhor que ou igual a',
            dateIs: 'A data é',
            dateIsNot: 'Date is not',
            dateBefore: 'A data não é',
            dateAfter: 'A data é depois',
            clear: 'Limpar',
            apply: 'Aplicar',
            matchAll: 'Corresponder a todos',
            matchAny: 'Corresponder a Qualquer',
            addRule: 'Adicionar regra',
            removeRule: 'Remover regra',
            accept: 'Sim',
            reject: 'Não',
            choose: 'Escolher',
            upload: 'Upload',
            cancel: 'Cancelar',
            dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["D","S","T","Q","Q","S","S"],
            monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            today: 'Hoje',
            weekHeader: 'Se',
            firstDayOfWeek: 0,
            dateFormat: 'dd/mm/yy',
            weak: 'Fraca',
            medium: 'Médio',
            strong: 'Forte',
            passwordPrompt: 'Insira a senha',
            emptyFilterMessage: 'Nenhum resultado encontrado',
            emptyMessage: 'Nenhuma opção disponível'
        }
    });

app.use(ToastService);
app.use(router);

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});


app.use(VueLoading, {
    backgroundColor: '#A9A9A9'
});

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Steps', Steps);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('InlineMessage', InlineMessage);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('ToggleButton', ToggleButton);
app.component('Toast', Toast);
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.mount('#app')
