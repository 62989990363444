<template>
  <Toast></Toast>
  <HeaderView></HeaderView>
  <router-view/>
  <FooterView></FooterView>
</template>


<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
export default {
  components: {FooterView, HeaderView}
}
</script>

<style lang="scss">

body {
  margin: 0px;
}
</style>

