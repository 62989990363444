<template>
  <div class="surface-section px-4 py-6 md:px-6 lg:px-8 text-center">
    <img src="@/assets/images/logo.png" alt="Image" height="50">
    <div class="font-medium text-900 mt-4 mb-3">&copy; 2022 Clinical Mind</div>
    <div class="flex align-items-center justify-content-center">
      <a class="cursor-pointer text-700 mr-5" href="https://www.instagram.com/clinicalmind_app" target="_blank">
        <i class="pi pi-instagram"></i>
      </a>
      <a class="cursor-pointer text-700 mr-5" href="https://api.whatsapp.com/send?phone=5511983868371" target="_blank">
        <i class="pi pi-whatsapp"></i>
      </a>
      <a class="cursor-pointer text-700" href="mailto:contato@clinicalmind.com.br" target="_blank">
        <i class="pi pi-inbox"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped>

</style>
