<template>
  <div class="surface-section py-3 px-6 shadow-5 flex align-items-center justify-content-between relative lg:static">
    <a class="cursor-pointer" @click.prevent="$router.push('/')">
      <img src="@/assets/images/logo.png" alt="Image" height="50">
    </a>
    <a class="cursor-pointer block lg:hidden text-700"
       v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 px-6 lg:px-0 z-2 shadow-2 lg:shadow-none">
      <section></section>

      <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
        <Button label="Login" class="font-bold p-button"></Button>
        <!--        <Button label="7 dias Grátis" @click="$router.push('register')" class="ml-3 p-button-outlined font-bold"></Button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderView"
}
</script>

<style scoped>

</style>
